// Font Family
$ssp: 'Source Sans Pro',sans-serif;
$nuns: 'Nunito Sans', sans-serif;

// Colors ---------------
$primary-color: #ff4a17;
$secondary-color: #14212b;
$text-color: #616161;
$white: #fff;
$black: #000;
$soft-gery: #f5f5f5;


// Responsive Variables
$xxl: '(min-width: 1600px)';
$xl: '(max-width: 1599px)';
$lg: '(max-width: 1199px)';
$md: '(max-width: 991px)';
$sm: '(max-width: 767px)';
$xsm: '(max-width: 575px)';
$tiny: '(max-width: 399px)';
