@use "./variables" as *;

/*======= Blog Standard =======*/
.blog-loop {
	&.standard-blog {
		.single-post-box {
			margin-bottom: 50px;

			.post-thumb {
				margin-bottom: 40px;
			}

			.post-meta {
				margin-bottom: 15px;

				ul li {
					display: inline-block;
					margin-right: 30px;
					font-weight: 600;

					@media #{$sm} {
						margin-right: 15px;
					}

					&,
					a {
						color: $text-color;

						&:hover {
							color: $primary-color;
						}
					}

					i {
						color: $primary-color;
						margin-right: 10px;
					}
				}
			}

			.post-content {
				font-size: 15px;
        text-align: justify;

        ul, ol {
          margin-left: 50px;
          list-style-type: auto;
        }

				.title {
					font-size: 35px;
					font-weight: 600;
					margin-bottom: 30px;

					@media #{$lg} {
						font-size: 28px;
					}

					@media #{$sm} {
						font-size: 24px;
					}

					@media #{$xsm} {
						font-size: 20px;
					}
				}

				.main-btn {
					margin-top: 40px;
				}
			}

    }
	}

}

/*======= Pagination =======*/

/*======= Sidebar =======*/
.sidebar {
	@media #{$md} {
		margin-top: 60px;
	}

	.widget {
		border: 2px solid #eaeaea;
		padding: 40px;
		margin-bottom: 30px;

		@media #{$lg} {
			padding: 40px 30px;
		}

		.widget-title {
			font-size: 24px;
			margin-bottom: 25px;
			padding-bottom: 25px;
			border-bottom: 2px solid #eaeaea;
		}

    &.author-widget {
			text-align: center;

			img.author-img {
				width: 150px;
				height: 150px;
				border-radius: 50%;
				margin-bottom: 25px;
			}

			.name {
				font-size: 24px;
			}

			.role {
				margin-bottom: 20px;
				line-height: 1;
			}

      .author-about {
        font-size: 15px;
        text-align: justify;
      }

    }

  }
}
