/*=======  Footer  =======*/
@use "./variables" as *;
footer {
	background-color: $secondary-color;
	position: relative;
	padding-top: 80px;
	z-index: 1;

	.widget {
		margin-bottom: 80px;

		@media #{$md} {
			margin-bottom: 60px;
		}

		@media #{$sm} {
			font-size: 16px;
		}

		@media #{$xsm} {
			margin-bottom: 45px;
		}

		&,
		a {
			color: #7d91a0;
		}

		a:hover {
			color: $white;
		}

		.widget-title,
		.footer-logo {
			color: $white;
			font-weight: 600;
			font-size: 26px;
			margin-bottom: 30px;

			@media #{$sm} {
				font-size: 22px;
			}
		}

		.social-links {
			margin-top: 25px;

			li {
				display: inline-block;
				margin-right: 15px;
				font-size: 15px;

				a:hover {
					color: $primary-color;
				}
			}
		}

		&.newsletter-widget {
			padding: 50px;
			border: 2px solid #1d2d3a;

			@media #{$md} {
				padding: 30px;
			}

			.widget-title {
				margin-bottom: 15px;
			}

			.newsletter-form {
				position: relative;
				padding-right: 280px;

				@media #{$md} {
					padding-right: 0;
				}

				input {
					width: 100%;
					background-color: transparent;
					border: none;
					border-bottom: 3px solid #33495b;
					line-height: 70px;
					padding: 0;
					height: 70px;
				}

				input,
				::placeholder {
					color: #7d91a0;
				}

				button {
					position: absolute;
					right: 0;
					top: 0;

					@media #{$md} {
						position: relative;
						margin-top: 20px;
					}
				}
			}
		}

  }

	.footer-copyright {
		border-top: 2px solid #1d2d3a;
		padding: 30px 0;
		position: relative;

		.copyright-text {
			color: #7d91a0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;

			@media #{$sm} {
				span {
					width: 100%;
					text-align: center;

					&:first-child {
						order: 2;
						margin-top: 10px;
					}
				}
			}
		}

  }

	.line-one,
	.line-two,
	.line-three,
	.line-four {
		position: absolute;
		z-index: -1;
	}

	.line-one {
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	.line-two {
		right: 0;
		top: 0;
	}

	.line-three {
		left: 0;
		top: 0;
	}

	.line-four {
		right: 0;
		bottom: 0;
	}

}
