/*=======  About CSS  =======*/
@use "./variables" as *;
.about-illustration-img {
	position: relative;
	min-height: 550px;
	display: flex;
	align-items: center;

	.illustration-img {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 50%;
		max-width: 655px;

		@media #{$md} {
			position: unset;
			transform: translate(0, 0);
			margin: 0 auto 50px;
			max-width: 550px;
		}

		@media #{$sm} {
			max-width: 450px;
		}
	}
}
