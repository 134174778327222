/*=======  Services  =======*/
@use "./variables" as *;
.service-section {
	position: relative;
	z-index: 1;

	&.shape-style-two {
		&::before {
			position: absolute;
			left: 90px;
			top: 200px;
			content: '';
			z-index: -1;
			background-image: url(../img/icons/star.png);
			height: 50px;
			width: 50px;
			background-position: center;
			background-size: 50px 50px;
			background-repeat: no-repeat;

			@media #{$md} {
				left: 20px;
				top: 150px;
			}

			@media #{$sm} {
				background-size: 40px 40px;
			}

			@media #{$xsm} {
				left: 15px;
				top: 50px;
				background-size: 30px 30px;
			}
		}

		&::after {
			position: absolute;
			right: 140px;
			top: 150px;
			content: '';
			z-index: -1;
			background-image: url(../img/icons/star.png);
			height: 40px;
			width: 40px;
			background-position: center;
			background-size: 40px 40px;
			background-repeat: no-repeat;

			@media #{$md} {
				right: 50px;
				top: 100px;
			}

			@media #{$sm} {
				background-size: 30px 30px;
			}

			@media #{$xsm} {
				right: 20px;
				top: 20%;
				background-size: 25px 25px;
			}
		}
	}

	&.service-line-shape {

		.line-one,
		.line-two {
			position: absolute;
			z-index: -1;

			@media #{$lg} {
				max-width: 300px;
			}

			@media #{$md} {
				max-width: 250px;
			}

			@media #{$sm} {
				max-width: 200px;
			}
		}

		.line-one {
			left: 0;
			top: 0;

			@media #{$xsm} {
				display: none;
			}
		}

		.line-two {
			right: 0;
			bottom: 0;
		}
	}
}

.service-boxes {

	.service-box-two {
		border-radius: 10px;
		padding: 40px 15px 60px;
		background-color: $white;
		position: relative;
		margin-top: 50px;

		@media #{$sm} {
			padding: 30px 15px 50px;
		}

		.icon {
			font-size: 75px;
			color: $primary-color;
		}

		h3 {
			font-size: 24px;
			margin-top: 20px;
			font-weight: 600;

			@media #{$lg} {
				font-size: 22px;
			}
		}

    &:hover {
    }
	}

}
