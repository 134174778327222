/*=======  Testimonial Two  =======*/
@use "./variables" as *;
.testimonial-section-two {
	position: relative;

	.testimonial-quote-icon {
		position: absolute;
		left: 190px;
		top: 170px;
	}

	.testimonial-quote-icon {
		position: absolute;
		left: 190px;
		top: 170px;

		@media #{$xl} {
			left: 140px;
			max-width: 250px;
		}

		@media #{$lg} {
			left: 100px;
		}

		@media #{$md} {
			max-width: 180px;
			left: 80px;
		}
	}

	.testimonial-items {
		.testimonial-item {
			font-size: 24px;
			line-height: 1.583;
			text-align: center;

			@media #{$md} {
				font-size: 20px;
			}

			@media #{$xsm} {
				font-size: 18px;
			}

			.quote-top,
			.quote-bottom {
				color: $primary-color;
				font-size: 16px;
				position: relative;
			}

			.quote-top {
				margin-right: 10px;
				top: -5px;
			}

			.quote-bottom {
				margin-left: 10px;
				bottom: -5px;
			}

			.author-img {
				margin-bottom: 50px;

				img {
					border-radius: 15px;
					height: 100px;
					width: 100px;
					box-shadow: 0 10px 30px 0 rgba(20, 33, 43, 0.32);
				}
			}

			.author-name {
				margin-top: 40px;

				h4 {
					font-size: 22px;
					font-weight: 600;
					letter-spacing: -1px;
				}

				span {
					font-weight: 600;
					color: $primary-color;
					font-size: 16px;
				}
			}
		}

  }

	.testimonial-dots {
		text-align: center;
		margin-top: 55px;

		@media #{$xsm} {
			display: none;
		}

		li {
			display: inline-block;
			margin: 0 10px;

			img {
				cursor: pointer;
				width: 60px;
				height: 60px;
				border-radius: 50%;
			}
		}
	}
}
