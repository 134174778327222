/*======= Blog Details  =======*/
@use "./variables" as *;
.post-details-wrap {
    .post-thumb {
        margin-bottom: 40px;
    }

    .post-meta {
        margin-bottom: 15px;

        ul li {
            display: inline-block;
            margin-right: 30px;
            font-weight: 600;

            @media #{$sm} {
                margin-right: 15px;
            }

            &,
            a {
                color: $text-color;

                &:hover {
                    color: $primary-color;
                }
            }

            i {
                color: $primary-color;
                margin-right: 10px;
            }
        }
    }

    .post-content {
        font-size: 15px;

        text-align: justify;

        ul, ol {
          margin-left: 50px;
          list-style-type: auto;
        }

        .title {
            font-size: 35px;
            font-weight: 600;
            margin-bottom: 30px;

            @media #{$lg} {
                font-size: 28px;
            }

            @media #{$sm} {
                font-size: 24px;
            }

            @media #{$xsm} {
                font-size: 20px;
            }
        }

        h2 {
          font-size: 24px;
          padding: 16px 0 16px 0;
        }
        h3 {
          font-size: 20px;
          padding: 12px 0 12px 0;
        }

        .text-center {
          text-align: center;
        }

        blockquote {
            padding: 50px 40px;
            background-color: $soft-gery;
            position: relative;
            z-index: 1;
            //font-size: 35px;
            //line-height: 1.2;
            //font-family: $ssp;
            //font-weight: 600;
            //color: $white;
            margin: 40px 0;

            @media #{$lg} {
                //font-size: 28px;
            }

            @media #{$sm} {
                //font-size: 24px;
            }

            @media #{$xsm} {
                padding: 40px 30px;
                //font-size: 20px;
            }

            &::before {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                content: "";
                background-image: url(../img/icons/quote-line.png);
                opacity: 0.06;
                background-size: auto;
                background-position: 38% 50%;
                background-repeat: no-repeat;

            }

        }

    }

    .post-footer {
        margin-top: 40px;

        ul li {
            display: inline-block;

            a {
                color: $text-color;

                &:hover {
                    color: $primary-color;
                }
            }

            &.title {
                font-weight: 700;
                color: $secondary-color;
                font-family: $ssp;
            }
        }

        .post-share {
            a {
                padding: 5px;
                margin-left: 5px;

                i {
                    font-size: 15px;
                }
            }
        }
    }
}

