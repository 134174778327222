/*=======  Contact Css  =======*/
@use "./variables" as *;
.contact-section {

    &.with-illustration-img {
        position: relative;

        .illustration-img {
            position: absolute;
            left: 2%;
            bottom: 0;
            z-index: 1;
            max-width: 645px;

            @media #{$xl} {}
        }

        .contact-form {
            position: relative;
            z-index: 2;

        }
    }

    &.contact-page {
        .contact-info {
            .contact-info-content {
                margin-bottom: 50px;

                ul {
                    margin-top: 30px;

                    li {
                        font-size: 20px;
                        margin-bottom: 15px;

                        &,
                        a {
                            color: $text-color;

                            i {
                                font-size: 18px;
                                color: $primary-color;
                                margin-right: 10px;
                            }
                        }

                        &.phone {

                            &,
                            a {
                                color: $secondary-color;
                                font-weight: 700;
                                font-size: 30px;
                            }

                            i {
                                font-size: 30px;
                            }
                        }
                    }
                }
            }

        }

        .contact-form {
            padding: 70px 0;
            margin-bottom: -170px;
            position: relative;
        }

        .contact-map {
            height: 320px;
            width: 100%;
            //background-color: #b7b7b7;

            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }
}
