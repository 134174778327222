/*=======  Working Process   =======*/
@use "./variables" as *;
@use "./mixin" as *;
.working-process-section {
	padding: 130px 0 0;
	//margin-top: -200px;
	position: relative;
	z-index: 4;

	.process-text {
		color: $text-color;
		font-size: 18px;
		line-height: 32px;
		padding-right: 40px;

		.process-loop {
			.single-process {
				margin-top: 30px;
				display: grid;
				grid-template-columns: 80px 1fr;
				grid-column-gap: 15px;

				@media #{$xsm} {
					display: block;
					margin-top: 50px;
				}

				.icon {
					height: 80px;
					width: 80px;
					line-height: 80px;
					text-align: center;
					border-radius: 50%;
					background-color: $white;
					color: $primary-color;
					font-size: 30px;
					box-shadow: 0 10px 32px 0 rgba(210, 210, 210, 0.4);
					@include transition(0.3s);
					position: relative;

					@media #{$xsm} {
						margin-bottom: 30px;
					}

					span {
						position: absolute;
						left: 0;
						top: -10px;
						height: 35px;
						width: 35px;
						background-color: $primary-color;
						color: $white;
						line-height: 35px;
						font-size: 13px;
						font-weight: 600;
						border-radius: 50%;
						@include transition(0.3s);
					}
				}

				.content {
					h4 {
						font-size: 24px;
						margin-bottom: 10px;
					}
				}

				&:hover {
					.icon {
						background-color: $primary-color;
						color: $white;
						box-shadow: 0 10px 32px 0 rgba(255, 74, 23, 0.4);

						span {
							background-color: $white;
							color: $primary-color;
						}
					}
				}
			}
		}
	}

}
