/*======= Banner =======*/
@use "./variables" as *;
.banner-section {
	position: relative;
	overflow: hidden;

	.single-banner {
		display: flex;
		align-items: center;
		background-size: cover;
		background-position: center;
		position: relative;
		z-index: 1;
		font-size: 22px;
		line-height: 1.5;
		color: $white;
		padding: 225px 0;

		@media #{$md} {
			padding: 200px 0;
			font-size: 18px;
		}

		@media #{$xsm} {
			padding: 150px 0;
		}

		@media #{$tiny} {
			padding: 130px 0;
		}

		&::before {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			content: '';
			background: $secondary-color;
			opacity: 0.65;
			z-index: -2;
		}

		.banner-content {
			h1 {
				color: $white;
				font-size: 90px;
				line-height: 1.1;

				@media #{$lg} {
					font-size: 56px;
				}

				@media #{$md} {
					font-size: 44px;
				}

				@media #{$sm} {
					font-size: 39px;
				}

				@media #{$xsm} {
					font-size: 29px;
				}

				@media #{$tiny} {
					font-size: 25px;
				}
			}

			.promo-text {
				text-transform: uppercase;
				font-size: 18px;
				font-weight: 700;
				color: $white;
				letter-spacing: 12px;
				margin-bottom: 30px;
				position: relative;
				padding-left: 45px;

				&::before {
					position: absolute;
					left: 8px;
					top: 50%;
					transform: rotate(-25deg) translateY(-50%);
					content: '';
					background-color: $white;
					height: 20px;
					width: 20px;
					clip-path: polygon(0 0, 0 100%, 100% 0);
				}

				@media #{$md} {
					font-size: 16px;
					letter-spacing: 10px;
				}

				@media #{$sm} {
					font-size: 15px;
					letter-spacing: 8px;
				}

				@media #{$xsm} {
					font-size: 14px;
					letter-spacing: 4px;
					padding-left: 30px;
				}

				@media #{$tiny} {
					letter-spacing: 2px;
				}
			}

			.btn-wrap {
				margin-top: 40px;

				li {
					display: inline-block;
					margin-right: 10px;
					margin-top: 10px;

					@media #{$xsm} {
						margin-right: 5px;
					}
				}
			}

			p {
				margin-top: 35px;
			}
		}

		.banner-shapes {
			&>div {
				position: absolute;
				left: 0;
				top: 0;
				background-color: $primary-color;
				clip-path: polygon(0 0, 0 100%, 100% 0);
				z-index: -1;
			}

			.one {
				width: 345px;
				height: 425px;
				opacity: 0.57;

				@media #{$xl} {
					width: 185px;
					height: 265px;
				}

				@media #{$lg} {
					width: 165px;
					height: 245px;
				}
			}

			.two {
				width: 520px;
				height: 650px;
				opacity: 0.37;

				@media #{$xl} {
					width: 320px;
					height: 450px;
				}

				@media #{$md} {
					width: 270px;
					height: 390px;
				}

				@media #{$tiny} {
					display: none;
				}
			}

			.three {
				width: 745px;
				height: 835px;
				opacity: 0.25;

				@media #{$xl} {
					width: 450px;
					height: 615px;
				}

				@media #{$md} {
					width: 365px;
					height: 540px;
				}

				@media #{$tiny} {
					display: none;
				}
			}

			.four {
				left: auto;
				right: 0;
				top: auto;
				bottom: 0;
				height: 285px;
				width: 270px;
				clip-path: polygon(100% 0, 0 100%, 100% 100%);
				opacity: 0.65;

				@media #{$xl} {
					height: 230px;
					width: 220px;
				}

				@media #{$xsm} {
					height: 130px;
					width: 155px;
				}
			}
		}
	}

	// Banner Style Two
	&.banner-section-two {
		.single-banner {
			padding-bottom: 220px;
			padding-top: 365px;

			@media #{$sm} {
				padding-top: 220px;
				padding-bottom: 150px;
			}

			&::before {
				background: radial-gradient(circle, rgba(1, 12, 21, 0.5) 0%, rgba(1, 12, 21, 0.9) 88%);
				background: -moz-radial-gradient(circle, rgba(1, 12, 21, 0.5) 0%, rgba(1, 12, 21, 0.9) 88%);
				background: -webkit-radial-gradient(circle, rgba(1, 12, 21, 0.5) 0%, rgba(1, 12, 21, 0.9) 88%);
				opacity: 1;
			}

			&::after {
				position: absolute;
				right: 0;
				bottom: 0;
				z-index: -1;
				content: "";
				width: 100%;
				height: 100%;
				background-image: url(../img/lines/09.png);
				background-repeat: no-repeat;
				background-position: right bottom;

				@media #{$xsm} {
					background-size: 300px;
				}
			}

			p {
				br {
					@media #{$md} {
						display: none;
					}
				}
			}
		}

		.slick-arrow {
			visibility: visible;
		}
	}

}
